import { Button, ComboboxItem, EmptyState } from 'components'
import { Combobox } from 'components/ComboboxV2/Combobox'
import { ServiceOrderHistory } from 'domains/customer/screens/History/components/ServiceOrderHistory/ServiceOrderHistory'
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce, useGetPatrimonyFragment, useToggle } from 'shared/hooks'
import { EventHistory } from './components/EventHistory/EventHistory'
import { ReactComponent as FiltersIcon } from 'assets/svg/serviceOrdersFilter.svg'
import { ReactComponent as PlusIcon } from 'assets/svg/plusSign.svg'

import styles from './History.module.scss'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Occurrence } from 'domains/customer/screens/History/components/Occurrence/Occurrence'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { AggregatedAccount, PatrimonyFragment } from 'services/account/types'
import { CreateManualOccurrenceModal } from 'domains/occurrence/components/CreateOccurrenceModal/CreateManualOccurrenceModal'

import {
  HistoryOption,
  HistoryTabLabels,
  historyOption,
  isHistoryTabLabel,
} from 'domains/customer/screens/History/utils'
import { useGetPatrimony } from 'services/patrimony/hooks/useGetPatrimony'
import { useGetAccounts } from 'shared/hooks/accounts/useGetAccounts'
import { useCustomerContext } from '../CustomerManagementTabs/CustomerProvider'
import { paths } from 'routes'

const allAccounts = { aggregatedAccountName: 'Todas', id: '' }
const allPatrimonies = { name: 'Todas', id: '' }
const MAX_RECORDS_PER_PAGE = 100

const History: React.FC = (): ReactElement => {
  const {
    customer,
    patrimony: patrimonyContext,
    account: accountContext,
  } = useCustomerContext()

  const manualOccurrence = useToggle()

  const [selectedPatrimony, setSelectedPatrimony] =
    useState<PatrimonyFragment | null>(null)

  const { data: patrimony } = useGetPatrimony(
    selectedPatrimony?.id || patrimonyContext?.id || '',
  )

  const [account, setAccount] = useState<ComboboxItem<AggregatedAccount>>({
    label: 'aggregatedAccountName',
    value: {
      id: accountContext?.id || '',
      aggregatedAccountName:
        `${accountContext?.code} (${accountContext?.serviceType?.name}) - ${accountContext?.name}` ||
        '',
    },
  })

  useEffect(() => {
    if (accountContext?.code) {
      setAccount({
        label: 'aggregatedAccountName',
        value: {
          id: accountContext.id,
          aggregatedAccountName: `${accountContext?.code} (${accountContext?.serviceType?.name}) - ${accountContext?.name}`,
        },
      })
    }
  }, [accountContext])

  const [searchTriggered, setSearchTriggered] = useState(true)
  const [filters, setfilters] = useState({
    account: '',
    patrimony: '',
  })
  const handleFilter = useDebounce((newFilters: Partial<typeof filters>) => {
    setfilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }))
  }, 700)

  const filtersProps = useToggle()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const initialTabLabel = searchParams.get('history-type')
  const initialTab = isHistoryTabLabel(initialTabLabel)
    ? historyOption[initialTabLabel]
    : historyOption['Ordens de serviço']

  const [selectedTab, setSelectedTab] = useState<HistoryOption>(initialTab)

  useEffect(() => {
    if (patrimony) {
      setSelectedPatrimony(patrimony)
    }
  }, [patrimony])

  const resolvedPatrimonyId = !selectedPatrimony?.id
    ? patrimonyContext?.id
    : selectedPatrimony?.id

  const {
    accounts,
    fetchNextAccountsPage,
    isFetching: isFetchingAccounts,
    refetch: refetchAccounts,
  } = useGetAccounts(
    !!selectedPatrimony?.id || !!patrimonyContext?.id,
    customer?.id,
    {
      name: filters.account,
    },
    MAX_RECORDS_PER_PAGE,
    selectedPatrimony?.id !== allPatrimonies.id
      ? resolvedPatrimonyId
      : undefined,
  )

  const {
    data: patrimonyFragment,
    isError: isErrorPatrimonyFragment,
    isFetching: isFetchingPatrimonyFragment,
    fetchNextPage: fetchNextPatrimonyPage,
  } = useGetPatrimonyFragment(customer?.id || '', filters.patrimony)

  const handleChangeItem = useCallback(
    (item: HistoryTabLabels) => {
      filtersProps.hide()

      const storedPatrimonyId = patrimonyContext?.id ?? ''
      const storedAccountId = accountContext?.id ?? ''
      const storedAggregatedAccountName =
        `${accountContext?.code} - ${accountContext?.name}` || ''

      setSelectedPatrimony(
        patrimonyFragment?.find((p) => p.id === storedPatrimonyId) || undefined,
      )
      setAccount({
        label: 'aggregatedAccountName',
        value: {
          id: storedAccountId,
          aggregatedAccountName: storedAggregatedAccountName,
        },
      })

      setSelectedTab(historyOption[item])
      setSearchParams((old) => {
        return { ...old, tab: old.get('tab'), 'history-type': item, page: '1' }
      })
    },
    [filtersProps, patrimonyFragment, setSearchParams, accountContext],
  )

  const handleHistory = (
    option: HistoryTabLabels,
  ): ReactElement | undefined => {
    if (!searchTriggered || !accountContext?.id)
      return <EmptyState type="EmptyDataFromBFF" />
    const history: Record<HistoryTabLabels, ReactElement> = {
      Ocorrências: (
        <Occurrence
          filtersProps={filtersProps}
          filteredAccount={account?.value.id ?? ''}
        />
      ),
      Eventos: (
        <EventHistory
          filtersProps={filtersProps}
          filteredAccount={account?.value.id ?? ''}
        />
      ),
      'Ordens de serviço': (
        <ServiceOrderHistory
          filtersProps={filtersProps}
          filteredAccounts={
            account?.value.aggregatedAccountName ===
            allAccounts.aggregatedAccountName
              ? accounts?.map((account) => account.id)
              : [account.value.id]
          }
        />
      ),
    }

    return history[option]
  }

  const accountsItems = useMemo(
    () =>
      selectedTab.title === 'Ordens de serviço'
        ? [allAccounts, ...(accounts || [])]
        : accounts,
    [accounts, selectedTab],
  )

  const patrimonyItems = useMemo(
    () => [allPatrimonies, ...(patrimonyFragment || [])],
    [patrimonyFragment],
  )

  return (
    <>
      <>
        <CreateManualOccurrenceModal
          isVisible={manualOccurrence.isVisible}
          onClose={manualOccurrence.hide}
          customerId={customer?.id}
        />
        <div className={styles.container}>
          <div className={styles.tabs}>
            {Object.entries(historyOption).map(([key, { title, icon }]) => (
              <span
                key={key}
                className={`${styles.tabItem} ${selectedTab.title === title && styles.tabItemSelected}`}
                onClick={() => handleChangeItem(key as HistoryTabLabels)}
              >
                {icon}
                {title}
              </span>
            ))}
            <div className={styles.separator} />
          </div>
          <div className={styles.actions}>
            <div>
              <Combobox
                label={{
                  text: 'Patrimônio',
                }}
                value={
                  selectedPatrimony
                    ? {
                        label: 'name',
                        value: selectedPatrimony,
                      }
                    : patrimony?.name || undefined
                }
                isError={isErrorPatrimonyFragment}
                onEndReached={fetchNextPatrimonyPage}
                onSearch={(search) => handleFilter({ patrimony: search })}
                isLoading={isFetchingPatrimonyFragment}
                onChange={(selected) => {
                  const fragment =
                    selected as unknown as ComboboxItem<PatrimonyFragment>
                  setSelectedPatrimony(fragment?.value)

                  setSearchTriggered(false)

                  navigate({ search: '?tab=history&page=1' })
                }}
                items={parseDataToComboboxV2(patrimonyItems, 'name')}
              />
            </div>
            <div className={styles.comboboxContainer}>
              <Combobox
                label={{ text: 'Conta' }}
                isLoading={isFetchingAccounts}
                onEndReached={fetchNextAccountsPage}
                items={parseDataToComboboxV2(
                  accountsItems,
                  'aggregatedAccountName',
                )}
                onChange={(selected) => {
                  const selectedAccount =
                    selected as ComboboxItem<AggregatedAccount>
                  setAccount(selectedAccount)
                  setSearchTriggered(false)
                  setSearchParams(
                    {
                      page: '1',
                      tab: 'history',
                      'history-type':
                        searchParams.get('history-type') || 'Ordens de serviço',
                    },
                    { replace: true },
                  )
                }}
                value={account}
                onSearch={(search) => handleFilter({ account: search })}
              />
            </div>
            <Button
              buttonTitle="Pesquisar"
              type="primary"
              onClick={() => {
                setSearchTriggered(true)
                refetchAccounts()
              }}
            />
            {selectedTab.title === 'Ocorrências' && (
              <Button
                buttonTitle="Nova ocorrência"
                type="secondary"
                icon={PlusIcon}
                width="174px"
                onClick={() => {
                  manualOccurrence.show()
                }}
              />
            )}
            {selectedTab.title === 'Ordens de serviço' && (
              <Button
                buttonTitle="Nova OS"
                icon={PlusIcon}
                onClick={() => {
                  navigate(paths.serviceOrder.create, {
                    state: {
                      account: {
                        id: account?.value?.id,
                        aggregatedName: account?.value?.aggregatedAccountName,
                        code: account?.value?.code,
                      },
                    },
                  })
                }}
                type="secondary"
              />
            )}
            <Button
              buttonTitle="Adicionar filtros"
              type="tertiary"
              icon={FiltersIcon}
              onClick={filtersProps.show}
            />
          </div>
          {handleHistory(selectedTab.title)}
        </div>
      </>
    </>
  )
}

export default History
